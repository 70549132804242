//IMPORTING IMAGES
function importAllApp(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}
const appImages = importAllApp(require.context('./App/imgs', true, /\.(png|jpe?g|svg|gif|mp4)$/));

const config = {
    menuApi: process.env.REACT_APP_MENU_API,
    companyName: 'Alban Elbasiouny | ألبان البسيوني',
    companyLogo: appImages['logo.png'],
    favIcon: '',
    siteDirection: '',
    meta: {
        main: {
            title: 'Alban Elbasiouny | ألبان البسيوني',
            description: "Al-Basyouni Dairy has experience in natural dairy products since 1990, resulting in high-quality taste and nutritional value. All products come from our own farmers and are manufactured in the Al-Basyouni Dairy Factory with 10 distribution branches spread throughout Egypt.",
            keywords: "Luxer ,Pastries, Sweets, Bakery,  Giza, Egypt, Egyptian desserts, Arabic,Sweets, Freshly baked, Handcrafted, Traditional flavors, Modern twists, Celebration cakes, Dessert ,catering, Local ingredients, Sweet indulgence,Sweet tooth, Gourmet treats, Confectionery, Dessert, paradise, Artisanal bakery, Taste of Egypt , حلويات, مخبوزات, معجنات, مصر، الجيزة, حلويات مصرية, حلويات عربية, خبز طازج, مصنوع يدوياً, نكهات تقليدية, لمسات حديثة, كعك الاحتفال, تقديم الحلوى, مكونات محلية, الانغماس الحلو, السكريات, الحلوى الفاخرة, مصنع الحلويات, بارادايس الحلوى, مخبز حرفي, طعم مصر, لوكسير",
            image: '',
            url: 'https://menu.luxer.me',
        },
        home: {
            title: '',
            description: '',
            keywords: '',
            image: '',
            url: 'https://menu.luxer.me/',
        },
        contact: {
            title: 'Contact Us | تواصل معنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://menu.luxer.me/contact',
        },
        about: {
            title: 'About Us | عنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://menu.luxer.me/about',
        },
        menus: {
            title: 'Our Menus | منيوهاتنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://menu.luxer.me/menus',
        },
    },
    homeSlides: [
        {
            title: 'لوكسير بيجمع الحبايب',
            description: 'لوكسير أصل الحلويات الشرقي و الغربي بنقدملك أفضل جودة و طعم من خلال أكتر من 23 فرع موجودين في كل مكان.',
            backgroundImage: appImages['slider/image-1.jpeg'],
            link: '/link-1'
        }
    ],
    contact: {
        hotline: [
            '16938',
        ],
        phones: [],
        emails: [],
        addresses: [
            'محل رقم 23 مول ادريس Address34 أمام سيد حنفي ومول سفن ستار التجمع الخامس Nasr City, Egypt',

        ],
        embedMapLink: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3455.1679137095016!2d31.420976875552135!3d30.003334574944315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAwJzEyLjAiTiAzMcKwMjUnMjQuOCJF!5e0!3m2!1sen!2seg!4v1709835591731!5m2!1sen!2seg',
        socialMedia: {
            facebook: 'https://www.facebook.com/Luxeregypt',
            instagram: 'https://www.instagram.com/luxeregypt/',
            x: '',
            linkedin: '',
            pinterest: '',
            behance: '',
            dribble: '',
            snapchat: '',
            tiktok: '',
        },
    },

    headerBackgroundImage: '',
    headerBackgroundColor: '',

    footerBackgroundColor: '',
    footerBackgroundImage: ''
}
// menuApi: 'https://fourdesign-adv-luxer-menu.onrender.com'

export default config;